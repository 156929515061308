import React, { useState, useEffect } from "react";
import ToolTip from "./ToolTip";

const CountdownTimer = () => {
  const startDate = new Date("January 11, 2022 15:07:55 GMT");
  const endDate = new Date("January 1, 2028 00:00:01 GMT");

  const calculateTimeDifference = () => {
    const currentTime = new Date();
    const difference = endDate - currentTime;
    const totalDifference = endDate - startDate;
    const completed = currentTime - startDate;
    console.log(difference, totalDifference, completed);
    const years = Math.floor(difference / (365.25 * 24 * 60 * 60 * 1000));
    const days = Math.floor(
      (difference % (365.25 * 24 * 60 * 60 * 1000)) / (24 * 60 * 60 * 1000)
    );
    const hours = Math.floor(
      (difference % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
    );
    const minutes = Math.floor((difference % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((difference % (60 * 1000)) / 1000);

    const remainingPercentage = Math.max(
      0,
      (1 - difference / totalDifference) * 100
    );

    const formatTwoDigits = (value) => (value < 10 ? `0${value}` : value);

    return {
      time: `${formatTwoDigits(years)}:${formatTwoDigits(
        days
      )}:${formatTwoDigits(hours)}:${formatTwoDigits(
        minutes
      )}:${formatTwoDigits(seconds)}`,
      percentage: remainingPercentage.toFixed(0),
    };
  };
  const calculateTimeCompleted = () => {
    const currentTime = new Date();
    const totalDifference = endDate - startDate;
    const completed = currentTime - startDate;
    console.log(completed, totalDifference, completed);
    const years = Math.floor(completed / (365.25 * 24 * 60 * 60 * 1000));
    const days = Math.floor(
      (completed % (365.25 * 24 * 60 * 60 * 1000)) / (24 * 60 * 60 * 1000)
    );
    const hours = Math.floor(
      (completed % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
    );
    const minutes = Math.floor((completed % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((completed % (60 * 1000)) / 1000);

    const formatTwoDigits = (value) => (value < 10 ? `0${value}` : value);

    return {
      time: `${formatTwoDigits(years)}:${formatTwoDigits(
        days
      )}:${formatTwoDigits(hours)}:${formatTwoDigits(
        minutes
      )}:${formatTwoDigits(seconds)}`,
    };
  };
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeDifference());
  const [timeCompleted, setTimeCompleted] = useState(calculateTimeCompleted());
  const completeTimeFormat = timeCompleted.time.split(":");
  console.log(completeTimeFormat);
  const reaminTimeArray = timeRemaining.time.split(":");
  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeRemaining(calculateTimeDifference());
    }, 1000);

    return () => clearInterval(timerId);
  }, []);
  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeCompleted(calculateTimeCompleted());
    }, 1000);

    return () => clearInterval(timerId);
  }, []);
  return (
    <div className="timerLg d-flex  align-items-center">
      <div className="liquidity">
        <a
          href="https://snowtrace.io/address/0x7d2040E4eeed5c9046993E9Ffd581404F943652f/contract/43114/readContract?chainId=43114"
          target="_blank"
          rel="noopener noreferrer"
        >
          Liquidity locked
        </a>
      </div>
      <div className="main_progress_sect">
        <div className="progress">
          <div class="progress-bar__wrapper">
            <progress
              id="progress-bar"
              value={timeRemaining.percentage}
              max="100"
            ></progress>
          </div>
          <span>{timeRemaining.percentage}%</span>
        </div>
        <div className="time_completed">
          <ToolTip
            text="Completed"
            // content="GMT: Tuesday, January 11, 2022 3:07:55 PM"
          />

          <div>
            <span>{completeTimeFormat[0]} YRS | </span>
            <span>{completeTimeFormat[1]} DAYS | </span>
            <span>{completeTimeFormat[2]} HRS | </span>
            <span>{completeTimeFormat[3]} MIN | </span>
            <span>{completeTimeFormat[4]} SEC </span>
          </div>
        </div>
      </div>
      <div className="remainTime">
        <ToolTip
          text="Remaining"
          // content="GMT: Saturday, January 1, 2028 12:00:01 AM"
        />

        {reaminTimeArray.length && (
          <div className="d-flex ">
            {/* YEARS */}
            <div className="timeSection">
              <div className="timeBox">{reaminTimeArray[0]}</div>
              <p>YRS</p>
            </div>

            <span>:</span>
            {/* DAYS */}
            <div className="timeSection">
              <div className="timeBox">{reaminTimeArray[1]}</div> <p>DAYS</p>
            </div>

            <span>:</span>
            {/* HOURS */}
            <div className="timeSection">
              <div className="timeBox">{reaminTimeArray[2]}</div>
              <p>HRS</p>
            </div>

            <span>:</span>
            {/* MINUTES */}
            <div className="timeSection">
              <div className="timeBox">{reaminTimeArray[3]}</div>
              <p>MINS</p>
            </div>
            <span>:</span>
            {/* SECONDS */}
            <div className="timeSection">
              <div className="timeBox">{reaminTimeArray[4]}</div>
              <p>SECS</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default CountdownTimer;
