// code added

import MetaMaskOnboarding from "@metamask/onboarding";
import Web3 from "web3";
import React, { useEffect, useState } from "react";
import contractsJSON from "../abi.json";
import mu from "../assets/images/mu.png";
import MuVaultWrap from "./muVaultWrap";
import CountdownTimer from "./Timer";
const ONBOARD_TEXT = "Click here to install MetaMask!";
const CONNECT_TEXT = "Connect To MetaMask";
const CONNECTED_TEXT = "Connected";

const MUAddress = "0xD036414fa2BCBb802691491E323BFf1348C5F4Ba";
const MUGAddress = "0xF7ed17f0Fb2B7C9D3DDBc9F0679b2e1098993e81";
const MUOAddress = "0x561f2209eA45023d796bF42F0402B33bc26610ce";
const MUVAddress = "0xdbA664085ae73CF4E4eb57954BDC88Be297B1f09";
const MUMAddress = "0x875E3352Baae84c38d930DD7Fd796178b2FCaa05";

const minABIM = [
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [{ name: "supply", type: "uint256" }],
    type: "function",
  },
];

const minABI = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
];

const coins = {
  MU: {
    type: "ERC20",
    options: {
      address: MUAddress,
      symbol: "MU",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xD036414fa2BCBb802691491E323BFf1348C5F4Ba/logo.png",
    },
  },
  MUG: {
    type: "ERC20",
    options: {
      address: MUGAddress,
      symbol: "MUG",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xF7ed17f0Fb2B7C9D3DDBc9F0679b2e1098993e81/logo.png",
    },
  },
  MUO: {
    type: "ERC20",
    options: {
      address: MUOAddress,
      symbol: "MUO",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xF7ed17f0Fb2B7C9D3DDBc9F0679b2e1098993e81/logo.png",
    },
  },
  MUV: {
    type: "ERC20",
    options: {
      address: MUVAddress,
      symbol: "MUV",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xdbA664085ae73CF4E4eb57954BDC88Be297B1f09/logo.png",
    },
  },
  MUM: {
    type: "ERC20",
    options: {
      address: MUMAddress,
      symbol: "MUM",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x875E3352Baae84c38d930DD7Fd796178b2FCaa05/logo.png",
    },
  },
};

// code added

const MidContent = (props) => {
  const ABI = [
    { inputs: [], stateMutability: "nonpayable", type: "constructor" },
    {
      inputs: [],
      name: "getMuUSDprice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getMugMuPrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getMugUSDPrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getMuoUSDPrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getMuvUSDPrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getPrices",
      outputs: [
        { internalType: "uint256", name: "muUSD", type: "uint256" },
        { internalType: "uint256", name: "muMUG", type: "uint256" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getReservesMU_MUG",
      outputs: [
        { internalType: "uint112", name: "reserve0", type: "uint112" },
        { internalType: "uint112", name: "reserve1", type: "uint112" },
        { internalType: "uint32", name: "blockTimestampLast", type: "uint32" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getReservesUSD_MU",
      outputs: [
        { internalType: "uint112", name: "reserve0", type: "uint112" },
        { internalType: "uint112", name: "reserve1", type: "uint112" },
        { internalType: "uint32", name: "blockTimestampLast", type: "uint32" },
      ],
      stateMutability: "view",
      type: "function",
    },
  ];
  const contractAddress = `0x06bC5F1C59a971cDff30431B100ae69f416115a2`;

  // my code

  const [login, setLogin] = React.useState(false);

  // my code
  // code added

  const [buttonText, setButtonText] = React.useState(ONBOARD_TEXT);
  const [isDisabled, setDisabled] = React.useState(false);
  const [diabledWrongNetwork, setDiabledWrongNetwork] = React.useState(false);
  const [whitelistContact, setWhitelistContact] = useState(true);
  const [wrongNetwork, setWrongNetwork] = React.useState(false);
  const [muBalance, setMUBalance] = React.useState(0);
  const [mugBalance, setMUGBalance] = React.useState(0);
  const [muoBalance, setMUOBalance] = React.useState(0);
  const [muvBalance, setMUVBalance] = React.useState(0);
  const [mumBalance, setMUMBalance] = React.useState(0);

  const [muMinedBalance, setMuMinedBalance] = React.useState(0);
  const [mugMinedBalance, setMugMinedBalance] = React.useState(0);
  const [muoMinedBalance, setMuoMinedBalance] = React.useState(0);
  const [muvMinedBalance, setMuvMinedBalance] = React.useState(0);
  const [mumMinedBalance, setMumMinedBalance] = React.useState(0);

  //  Approx Formated Values
  const [muBalanceApprox, setMUBalanceApprox] = React.useState(0);
  const [mugBalanceApprox, setMUGBalanceApprox] = React.useState(0);
  const [muoBalanceApprox, setMUOBalanceApprox] = React.useState(0);
  //  Approx Formated Values
  const [mugMUPrice, setMugMUPrice] = useState(0);

  const setWhitelistContactFn = () => {
    //setWhitelistContact(false);
    setSwapMuVaultModal(false);
  };

  const [accounts, setAccounts] = React.useState([]);
  const onboarding = React.useRef();

  const [mUGAlreadyApprovedAmount, setMUGAlreadyApprovedAmount] = useState(0);

  React.useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
    // pastLogsOfMU();
  }, []);

  React.useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length > 0) {
        setButtonText(CONNECTED_TEXT);
        setDisabled(true);
        onboarding.current.stopOnboarding();
        getBalance("MU");
        getBalance("MUG");
        getBalance("MUO");
        getBalance("MUV");
        getBalance("MUM");
        getTotalSupply("MU");
        getTotalSupply("MUG");
        getTotalSupply("MUO");
        getTotalSupply("MUV");
        setLogin(true);
      } else {
        setButtonText(CONNECT_TEXT);
        setDisabled(false);
        setMUBalance(0);
        setMUGBalance(0);
        setMUOBalance(0);
        setMUVBalance(0);
        setMUMBalance(0);
        setLogin(false);
      }
    }
  }, [accounts]);

  React.useEffect(() => {
    function handleNewAccounts(newAccounts) {
      setAccounts(newAccounts);
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length === 0 && window.ethereum.selectedAddress) {
        handleNewAccounts([window.ethereum.selectedAddress]);
        checkChainId();
      }
    }
  });

  const handleChainChanged = (_chainId) => {
    // checking if chain is of avalanche network
    if (_chainId !== "0xa86a") {
      setButtonText("Wrong Network! Select Avalanche");
      setDisabled(true);
      setLogin(true);
      setWrongNetwork(true);
      setDiabledWrongNetwork(true);
      setMUBalance(0);
      setMUGBalance(0);
      setMUOBalance(0);
      setMUVBalance(0);
      setMUMBalance(0);
    } else {
      setWrongNetwork(false);
      setDiabledWrongNetwork(false);
      getBalance("MU");
      getBalance("MUG");
      getBalance("MUO");
      getBalance("MUV");
      getBalance("MUM");
      getTotalSupply("MU");
      getTotalSupply("MUG");
      getTotalSupply("MUO");
      getTotalSupply("MUV");

      if (window.ethereum.selectedAddress) {
        setButtonText(CONNECTED_TEXT);
        setDisabled(true);
        setLogin(true);
        onboarding.current.stopOnboarding();
        getApprovedAmount();
      } else {
        setButtonText(CONNECT_TEXT);
        setDisabled(false);
        setLogin(false);
      }
    }
  };
  const checkChainId = async () => {
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    handleChainChanged(chainId);
  };

  React.useEffect(() => {
    function handleNewAccounts(newAccounts) {
      setAccounts(newAccounts);
      checkChainId();
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum.on("accountsChanged", handleNewAccounts);
      window.ethereum.on("chainChanged", handleChainChanged);
      return () => {
        window.ethereum.removeListener("accountsChanged", handleNewAccounts);
        window.ethereum.on("chainChanged", handleChainChanged);
      };
    }
  }, []);

  const addCoinToWallet = async (coin = "MU") => {
    try {
      if (!wrongNetwork) {
        await window.ethereum
          .request({
            method: "wallet_watchAsset",
            params: coins[coin],
          })
          .then(() => console.log("Success"));
      }
    } catch (addError) {
      console.log(addError);
    }
  };

  const getBalance = async (type) => {
    try {
      let address = coins[type].options.address;
      let web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(minABI, address);
      const result = await contract.methods
        .balanceOf(window.ethereum.selectedAddress)
        .call();
      const format = web3.utils.fromWei(result);
      if (type === "MU") {
        setMUBalance(format);
        setMUBalanceApprox((Math.round(muBalance * 100) / 100).toFixed(2));
        //console.log("The approx valu for MUC:", muBalanceApprox);
      } else if (type === "MUG") {
        setMUGBalance(format);
      } else if (type === "MUO") {
        setMUOBalance(format);
      } else if (type === "MUV") {
        setMUVBalance(format);
      } else if (type === "MUM") {
        setMUMBalance(format);
      }

      setMUGBalanceApprox(true);
      setMUOBalanceApprox(true);
    } catch (addError) {
      console.log(addError);
    }
  };

  const getTotalSupply = async (type) => {
    //console.log("/////////////////////")
    try {
      let minedAddress = coins[type].options.address;
      let minedWeb3 = new Web3(window.ethereum);
      //console.log("My wallet Address",window.ethereum.selectedAddress)
      const minedContract = new minedWeb3.eth.Contract(minABIM, minedAddress);
      //console.log("llllllllll coinnnn:::::::::",minedContract)

      const minedResult = await minedContract.methods.totalSupply().call();

      const minedFormat = minedWeb3.utils.fromWei(minedResult);
      // console.log("llllllllll kasllolol:::::::::",minedFormat)

      if (type === "MU") {
        setMuMinedBalance(minedFormat);
      } else if (type === "MUG") {
        setMugMinedBalance(minedFormat);
      } else if (type === "MUO") {
        setMuoMinedBalance(minedFormat);
      } else if (type === "MUV") {
        setMuvMinedBalance(minedFormat);
      }
    } catch (addError) {
      console.log(addError);
    }
  };

  const onClick = () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((newAccounts) => setAccounts(newAccounts));
    } else {
      onboarding.current.startOnboarding();
    }
  };

  const getApprovedAmount = () => {
    const mugContractAddress = `0xF7ed17f0Fb2B7C9D3DDBc9F0679b2e1098993e81`;
    const muoContractAddress = `0x561f2209eA45023d796bF42F0402B33bc26610ce`;
    const web3 = new Web3(window.ethereum);

    const mugContract = new web3.eth.Contract(
      contractsJSON.muGOLDContract,
      mugContractAddress
    );
    console.log(mugContract);

    //console.log("Trying approved amount");
    mugContract.methods
      .allowance(window.ethereum.selectedAddress, muoContractAddress)
      .call()
      .then((data) => {
        //console.log("Already Approved Amount", data);
        setMUGAlreadyApprovedAmount(web3.utils.fromWei(data));
      });
  };

  // code added

  // The MU value in Coins

  const [muUSDPrice, setMuUSDPrice] = useState(0);
  const [mugUSDPrice, setMugUSDPrice] = useState(0);
  const [muoUSDPrice, setMuoUSDPrice] = useState(0);
  const [muvUSDPrice, setMuvUSDPrice] = useState(0);
  const [getError, setError] = useState("");
  const [speakDisabled, setSpeakDisabled] = useState(false);
  const [speakNow, setSpeakNow] = useState(new Date().getTime());

  useEffect(() => {
    getPrices();
    getPoolInfo();
  }, []);

  const speak = (message) => {
    try {
      if (speakDisabled) {
        let voices = window.speechSynthesis.getVoices();
        let msg = new SpeechSynthesisUtterance(message);
        voices = voices.filter((d) => d.lang === "en-GB");
        msg.voice = voices[0];
        window.speechSynthesis.speak(msg);
        console.log("Spoke");
      } else {
        console.log("Don't speak");
      }
    } catch (err) {
      console.log("error speak", err);
    }
  };

  useEffect(() => {
    getPrices();
  }, [speakNow]);

  useEffect(() => {
    let intervalForPrice = setInterval(
      () => setSpeakNow(new Date().getTime()),
      10000
    );
    return () => clearInterval(intervalForPrice);
  }, []);

  const getPrices = async () => {
    const web3 = new Web3(`https://api.avax.network/ext/bc/C/rpc`);
    const contract = new web3.eth.Contract(ABI, contractAddress);
    // console.log(contract)
    contract.methods
      .getMuUSDprice()
      .call()
      .then((price) => {
        price = web3.utils.fromWei(price, "ether");
        price = parseFloat(price).toFixed(5);
        setMuUSDPrice(price);
        if (price > muUSDPrice && muUSDPrice > 0) {
          speak("Price went up for MU/USD. It's now " + price);
        } else if (price < muUSDPrice && muUSDPrice > 0) {
          speak("Price went down for MU/USD. It's now " + price);
        }
      });

    contract.methods
      .getMugUSDPrice()
      .call()
      .then((price) => {
        price = web3.utils.fromWei(price, "ether");
        price = parseFloat(price).toFixed(5);
        setMugUSDPrice(price);
        console.log(mugUSDPrice);
        if (price > mugUSDPrice && mugUSDPrice > 0) {
          speak("Price went up for MU Gold USD. It's now " + price);
        } else if (price < mugUSDPrice && mugUSDPrice > 0) {
          speak("Price went down for MU Gold USD. It's now " + price);
        }
      });

    contract.methods
      .getMuoUSDPrice()
      .call()
      .then((price) => {
        price = web3.utils.fromWei(price, "ether");
        price = parseFloat(price).toFixed(5);
        setMuoUSDPrice(price);
        console.log(muoUSDPrice);
        if (price > muoUSDPrice && muoUSDPrice > 0) {
          speak("Price went up for MU Ore USD. It's now " + price);
        } else if (price < muoUSDPrice && muoUSDPrice > 0) {
          speak("Price went down for MU Ore USD. It's now " + price);
        }
      });

    contract.methods
      .getMuvUSDPrice()
      .call()
      .then((price) => {
        price = web3.utils.fromWei(price, "ether");
        price = parseFloat(price).toFixed(5);
        setMuvUSDPrice(price);
        console.log(muvUSDPrice);
        if (price > muvUSDPrice && muvUSDPrice > 0) {
          speak("Price went up for MU Vault USD. It's now " + price);
        } else if (price < muvUSDPrice && muvUSDPrice > 0) {
          speak("Price went down for MU Vault USD. It's now " + price);
        }
      });

    contract.methods
      .getMugMuPrice()
      .call()
      .then((price) => {
        price = web3.utils.fromWei(price, "ether");
        price = parseFloat(price).toFixed(5);
        setMugMUPrice(price);
        if (price > mugMUPrice && mugMUPrice > 0) {
          speak(
            "MU Gold conversion from MU went up. It's now " +
              price +
              " for 1 MU Gold"
          );
        } else if (price < mugMUPrice && mugMUPrice > 0) {
          speak(
            "MU Gold conversion from MU went down. It's now " +
              price +
              " for 1 MU Gold"
          );
        }
      });
  };

  const getPoolInfo = () => {
    const web3 = new Web3(`https://api.avax.network/ext/bc/C/rpc`);
    const contract = new web3.eth.Contract(
      contractsJSON.poolInfo.abi,
      contractsJSON.poolInfo.address
    );
    //console.log("heya", contract);
  };

  useEffect(() => {
    if (speakDisabled) {
      speak("Speak Enabled");
    }
  }, [speakDisabled]);

  let dollarUSLocale = Intl.NumberFormat("en-US");

  // The MU value in Coins

  const [swapModal, setSwapModal] = useState(false);

  const setSwapModalFn = () => {
    props.swapModal();
  };
  const closeSwapModal = () => {
    setSwapModal(false);
  };

  const [swapMuVaultModal, setSwapMuVaultModal] = useState(false);

  const setSwapMuVaultModalFn = () => {
    setSwapMuVaultModal(true);
  };
  const closeSwapMuVaultModal = () => {
    setSwapMuVaultModal(false);
  };

  return (
    <>
      <div className="contentMain f-1 d-flex f-column f-align-center">
        <div className="container">
          <div className="headerMain text-center dashUnder">
            <h1>MU</h1>
            <h3>It's not a MEME</h3>
          </div>

          <div className="mucards d-flex f-justify-center">
            <div className="card">
              <div className="miningInfo MuCoins">
                <p>
                  {dollarUSLocale.format(
                    muMinedBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
                  )}{" "}
                  total supply with a value{" "}
                  <span>
                    $
                    {dollarUSLocale.format(
                      (muMinedBalance * props.muUSDPrice)
                        .toString()
                        .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                    )}
                  </span>
                </p>
              </div>
              <figure className="muCoin">
                <img src={mu} alt="" />
              </figure>
              <div className="muAmtComp">
                <ul className="w-100">
                  <li className="d-flex f-justify-between">
                    <h5>MU Coin</h5>
                    <span className="valuationSpan">
                      $
                      {dollarUSLocale.format(
                        (muBalance * props.muUSDPrice)
                          .toString()
                          .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                      )}
                    </span>
                  </li>
                  <li className="d-flex secondInfoRow f-justify-between">
                    <span>
                      {dollarUSLocale.format(
                        muBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
                      )}
                    </span>
                    <span className="valueSpan">
                      @ $
                      {dollarUSLocale.format(
                        props.muUSDPrice
                          .toString()
                          .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                      )}
                    </span>
                  </li>
                </ul>
              </div>
              <footer>
                {/* {login ? 
                                <button onClick={() => addCoinToWallet("MU")} className={wrongNetwork ? "btn btn-gradient btn-default disabled" : "btn btn-gradient btn-default"} >
                                    Add MU token to your wallet
                                </button>
                                : 
                                <button className="btn btn-gradient btn-default" onClick={onClick}>
                                Connect your wallet now
                            </button>
                            } */}
                <a
                  href="https://traderjoexyz.com/avalanche/trade?inputCurrency=0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664&outputCurrency=0xD036414fa2BCBb802691491E323BFf1348C5F4Ba"
                  target="_blank"
                >
                  <button className="btn btn-gradient btn-default">
                    Get MU Coin
                  </button>
                </a>
              </footer>
              {login ? (
                <span
                  className={
                    wrongNetwork ? "addToWalet disabled" : "addToWalet"
                  }
                  onClick={() => addCoinToWallet("MU")}
                >
                  Add MU coin to your wallet
                </span>
              ) : (
                <span className="addToWalet" onClick={onClick}>
                  Connect your wallet now
                </span>
              )}
            </div>
            <div className="card muGold">
              <div className="miningInfo MuGold">
                <p>
                  {" "}
                  {dollarUSLocale.format(
                    mugMinedBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
                  )}{" "}
                  total supply with a value{" "}
                  <span>
                    $
                    {dollarUSLocale.format(
                      (mugMinedBalance * props.mugUSDPrice)
                        .toString()
                        .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                    )}
                  </span>
                </p>
              </div>
              <figure className="muCoin ">
                <img src={mu} alt="" />
              </figure>
              <div className="muAmtComp">
                <div className="muGoldValues">
                  <span className="textMu lefts">1 MU Gold</span>
                  <span className="textMu centers">=</span>
                  <span className="textMu rights">
                    {dollarUSLocale.format(
                      mugMUPrice.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
                    )}{" "}
                    MU Coins
                  </span>
                </div>
                <ul className="w-100">
                  <li className="d-flex f-justify-between">
                    <h5>MU Gold</h5>
                    <span className="valuationSpan">
                      $
                      {dollarUSLocale.format(
                        (mugBalance * props.mugUSDPrice)
                          .toString()
                          .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                      )}
                    </span>
                  </li>
                  <li className="d-flex secondInfoRow f-justify-between">
                    <span>
                      {mugBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}
                    </span>
                    <span className="valueSpan">
                      @ $
                      {dollarUSLocale.format(
                        props.mugUSDPrice
                          .toString()
                          .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                      )}
                    </span>
                  </li>
                </ul>
              </div>
              <footer>
                {/* {login ? 
                                <button onClick={() => addCoinToWallet("MUG")} className={wrongNetwork ? "btn btn-gradient btn-default disabled" : "btn btn-gradient btn-default"} >
                                    Add MU Gold token to your wallet
                                </button>
                                : 
                                <button className="btn btn-gradient btn-default" onClick={onClick}>
                                Connect your wallet now
                            </button>
                            } */}
                <a
                  href="https://traderjoexyz.com/avalanche/trade?inputCurrency=0xD036414fa2BCBb802691491E323BFf1348C5F4Ba&outputCurrency=0xF7ed17f0Fb2B7C9D3DDBc9F0679b2e1098993e81"
                  target="_blank"
                >
                  <button className="btn btn-gradient btn-default">
                    Get MU Gold
                  </button>
                </a>
              </footer>

              {login ? (
                <span
                  className={
                    wrongNetwork ? "addToWalet disabled" : "addToWalet"
                  }
                  onClick={() => addCoinToWallet("MUG")}
                >
                  Add MU Gold to your wallet
                </span>
              ) : (
                <span className="addToWalet" onClick={onClick}>
                  Connect your wallet now
                </span>
              )}
            </div>
            <div className="card muOre">
              <div div className="miningInfo MuOre">
                <p>
                  {dollarUSLocale.format(
                    muoMinedBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
                  )}
                  &nbsp;minted with a value of{" "}
                  <span>
                    $
                    {dollarUSLocale.format(
                      (muoMinedBalance * props.mugUSDPrice)
                        .toString()
                        .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                    )}
                  </span>
                </p>
              </div>
              <figure className="muCoin ">
                <img src={mu} alt="" />
              </figure>
              <div className="muAmtComp">
                <ul className="w-100">
                  <li className="d-flex f-justify-between">
                    <h5>MU Ore</h5>
                    <span className="valuationSpan">
                      $
                      {dollarUSLocale.format(
                        (muoBalance * props.mugUSDPrice)
                          .toString()
                          .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                      )}
                    </span>
                  </li>
                  <li className="d-flex secondInfoRow f-justify-between">
                    <span>
                      {dollarUSLocale.format(
                        muoBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
                      )}
                    </span>
                    <span className="valueSpan">
                      @ $
                      {dollarUSLocale.format(
                        props.mugUSDPrice
                          .toString()
                          .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                      )}
                    </span>
                  </li>
                </ul>
              </div>
              <footer>
                {/* {login ? 
                                <button onClick={() => addCoinToWallet("MUO")} className={wrongNetwork ? "btn btn-gradient btn-default disabled" : "btn btn-gradient btn-default"} >
                                    Add MU Ore token to your wallet
                                </button>
                                : 
                                <button className="btn btn-gradient btn-default" onClick={onClick}>
                                Connect your wallet now
                            </button>
                            } */}
                <button
                  onClick={() => setSwapModalFn()}
                  className="btn btn-gradient btn-default"
                >
                  Get MU Ore
                </button>
              </footer>
              {login ? (
                <span
                  className={
                    wrongNetwork ? "addToWalet disabled" : "addToWalet"
                  }
                  onClick={() => addCoinToWallet("MUO")}
                >
                  Add MU Ore to your wallet
                </span>
              ) : (
                <span className="addToWalet" onClick={onClick}>
                  Connect your wallet now
                </span>
              )}
            </div>
            <div className="card muVault">
              <div div className="miningInfo MuVault">
                <p>
                  {dollarUSLocale.format(
                    muvMinedBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
                  )}
                  &nbsp;minted with a value of{" "}
                  <span>
                    $
                    {dollarUSLocale.format(
                      (muvMinedBalance * props.mugUSDPrice)
                        .toString()
                        .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                    )}
                  </span>
                </p>
              </div>
              <figure className="muCoin ">
                <img src={mu} alt="" />
              </figure>
              <div className="muAmtComp">
                <ul className="w-100">
                  <li className="d-flex f-justify-between">
                    <h5>MU Vault</h5>
                    <span className="valuationSpan">
                      $
                      {dollarUSLocale.format(
                        (muvBalance * props.mugUSDPrice)
                          .toString()
                          .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                      )}
                    </span>
                  </li>
                  <li className="d-flex secondInfoRow f-justify-between">
                    <span>
                      {dollarUSLocale.format(
                        muvBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
                      )}
                    </span>
                    <span className="valueSpan">
                      @ $
                      {dollarUSLocale.format(
                        props.mugUSDPrice
                          .toString()
                          .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                      )}
                    </span>
                  </li>
                </ul>
              </div>
              <footer>
                {/* <button className="btn btn-gradient btn-default comingSoonBtn disabled">
                                Get MU Vault
                                </button> */}
                <button
                  className="btn btn-gradient btn-default"
                  onClick={() => setSwapMuVaultModalFn()}
                >
                  Get MU Vault
                </button>
              </footer>
              {login ? (
                <span
                  className={
                    wrongNetwork ? "addToWalet disabled" : "addToWalet"
                  }
                  onClick={() => addCoinToWallet("MUV")}
                >
                  Add MU Vault to your wallet
                </span>
              ) : (
                <span className="addToWalet" onClick={onClick}>
                  Connect your wallet now
                </span>
              )}
            </div>
          </div>
          <CountdownTimer />
        </div>
      </div>

      {swapMuVaultModal && (
        <MuVaultWrap
          closeSwapMuVaultModal={closeSwapMuVaultModal}
          setWhitelistContactFn={setWhitelistContactFn}
          whitelistContact={whitelistContact}
        />
      )}
    </>
  );
};

export default MidContent;
