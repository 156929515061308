import React from 'react';
//import logo from './logo.svg';
import IndexPage from './pages/indexPage';
import contractsJSON from "./abi.json";
//import './App.css';
import './assets/css/common.css';
import './assets/css/defaults.css';
import './assets/css/theme-dark.css';
import './assets/css/theme-dark-responsive.css';
import './assets/css/theme-lite.css';
import './assets/css/theme-lite-responsive.css';

  const App = () => {
  return (
    
     <IndexPage />
     
  );
}

export default App;
