import React, { useState } from "react";
import MuGoldWrap from "./muGoldWrap";
import MuOreWrap from "./muOreWrap";

const SwapModal = (props) => {
  const [muGoldWrap, setMuGoldWrap] = useState(true);
  const [muOreWrap, setMuOreWrap] = useState(false);

  const setMuGoldWrapFn = () => {
    setMuGoldWrap(true);
    setMuOreWrap(false);
  };

  const setMuOreWrapFn = () => {
    setMuOreWrap(true);
    setMuGoldWrap(false);
  };

  return (
    //  Swap Modal section
    <div id="swapModal" className="modal show-modal">
      {/* <div className="modal-content"> */}

      {/* MuG to MuO  */}

      {muGoldWrap && (
        <MuGoldWrap
          setMuOreWrapFn={setMuOreWrapFn}
          closeSwapModal={props.closeSwapModal}
          setMuGoldWrapFn={setMuGoldWrapFn}
          setAmountConverted={props.setAmountConverted}
          amountConverted={props.amountConverted}
        />
      )}

      {/* MuG to MuO  */}

      {/* MuO to MuG */}

      {muOreWrap && (
        <MuOreWrap
          setMuGoldWrapFn={setMuGoldWrapFn}
          closeSwapModal={props.closeSwapModal}
          setAmountConverted={props.setAmountConverted}
          amountConverted={props.amountConverted}
        />
      )}

      {/* MuO to MuG */}
    </div>

    // </div>

    // Swap Modal section
  );
};

export default SwapModal;
