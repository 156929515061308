import Web3 from "web3";
import React, { useState, useLayoutEffect, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import MidContent from "../components/midContent";
import SwapModal from "../components/swapModal";
import MuVaultWrap from "../components/muVaultWrap";

import SwapMuMoneyModal from "../components/swapMuMoneyModal";
// import Sidebarfile from './sidebarfile/Sidebarfile';

import contractsJSON from "../abi.json";

const IndexPage = (props) => {
  // Code added

  const ABI = [
    { inputs: [], stateMutability: "nonpayable", type: "constructor" },
    {
      inputs: [],
      name: "getMuUSDprice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getMugMuPrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getMugUSDPrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getPrices",
      outputs: [
        { internalType: "uint256", name: "muUSD", type: "uint256" },
        { internalType: "uint256", name: "muMUG", type: "uint256" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getReservesMU_MUG",
      outputs: [
        { internalType: "uint112", name: "reserve0", type: "uint112" },
        { internalType: "uint112", name: "reserve1", type: "uint112" },
        { internalType: "uint32", name: "blockTimestampLast", type: "uint32" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getReservesUSD_MU",
      outputs: [
        { internalType: "uint112", name: "reserve0", type: "uint112" },
        { internalType: "uint112", name: "reserve1", type: "uint112" },
        { internalType: "uint32", name: "blockTimestampLast", type: "uint32" },
      ],
      stateMutability: "view",
      type: "function",
    },
  ];
  const contractAddress = `0x06bC5F1C59a971cDff30431B100ae69f416115a2`;

  const [muUSDPrice, setMuUSDPrice] = useState(0);
  const [mugUSDPrice, setMugUSDPrice] = useState(0);
  const [mugMUPrice, setMugMUPrice] = useState(0);
  const [muvPrice, setMuvPrice] = useState(0);
  const [mumUSDPrice, setMumUSDPrice] = useState(0);
  const [muAvaxSushi, setMuAvaxSushi] = useState(0);
  const [muAvaxTraderjoe, setMuAvaxTraderjoe] = useState(0);
  const [muDaiTraderjoe, setMuDaiTraderjoe] = useState(0);
  const [muMimTraderjoe, setMuMimTraderjoe] = useState(0);
  const [muUSDCETraderjoe, setMuUSDCETraderjoe] = useState(0);
  const [muUSDTETraderjoe, setMuUSDTETraderjoe] = useState(0);
  const [getError, setError] = useState("");
  const [speakDisabled, setSpeakDisabled] = useState(false);
  const [enableButtonText, setEnableButtonText] = useState(
    "Enable Speak if price change"
  );
  const [speakNow, setSpeakNow] = useState(new Date().getTime());

  useEffect(() => {
    getPrices();
    getPoolInfo();
  }, []);

  const speak = (message) => {
    try {
      if (speakDisabled) {
        let voices = window.speechSynthesis.getVoices();
        let msg = new SpeechSynthesisUtterance(message);
        voices = voices.filter((d) => d.lang === "en-GB");
        msg.voice = voices[0];
        window.speechSynthesis.speak(msg);
        console.log("Spoke");
      } else {
        console.log("Don't speak");
      }
    } catch (err) {
      console.log("error speak", err);
    }
  };

  useEffect(() => {
    getPrices();
  }, [speakNow]);

  useEffect(() => {
    let intervalForPrice = setInterval(
      () => setSpeakNow(new Date().getTime()),
      10000
    );
    return () => clearInterval(intervalForPrice);
  }, []);

  const getPrices = async () => {
    // if (!window.ethereum) {
    //   setError("Install Meta Mask and Connect");
    // }
    // await window.ethereum.enable();
    // console.log(window.ethereum);
    const web3 = new Web3(`https://api.avax.network/ext/bc/C/rpc`);
    // await window.ethereum.enable();
    const contract = new web3.eth.Contract(ABI, contractAddress);
    // console.log(contract)
    contract.methods
      .getMuUSDprice()
      .call()
      .then((price) => {
        price = web3.utils.fromWei(price, "ether");
        price = parseFloat(price).toFixed(5);
        setMuUSDPrice(price);
        if (price > muUSDPrice && muUSDPrice > 0) {
          speak("Price went up for MU/USD. It's now " + price);
        } else if (price < muUSDPrice && muUSDPrice > 0) {
          speak("Price went down for MU/USD. It's now " + price);
        }
      });

    contract.methods
      .getMugUSDPrice()
      .call()
      .then((price) => {
        price = web3.utils.fromWei(price, "ether");
        price = parseFloat(price).toFixed(5);
        setMugUSDPrice(price);
        console.log(mugUSDPrice);
        if (price > mugUSDPrice && mugUSDPrice > 0) {
          speak("Price went up for MU Gold USD. It's now " + price);
        } else if (price < mugUSDPrice && mugUSDPrice > 0) {
          speak("Price went down for MU Gold USD. It's now " + price);
        }
      });

    contract.methods
      .getMugMuPrice()
      .call()
      .then((price) => {
        price = web3.utils.fromWei(price, "ether");
        price = parseFloat(price).toFixed(5);
        setMugMUPrice(price);
        if (price > mugMUPrice && mugMUPrice > 0) {
          speak(
            "MU Gold conversion from MU went up. It's now " +
              price +
              " for 1 MU Gold"
          );
        } else if (price < mugMUPrice && mugMUPrice > 0) {
          speak(
            "MU Gold conversion from MU went down. It's now " +
              price +
              " for 1 MU Gold"
          );
        }
      });
  };

  const getPoolInfo = () => {
    const web3 = new Web3(`https://api.avax.network/ext/bc/C/rpc`);
    const contract = new web3.eth.Contract(
      contractsJSON.poolInfo.abi,
      contractsJSON.poolInfo.address
    );
    //console.log("heya", contract);
    contract.methods
      .get_mu_avax_sushiswap_pool_info()
      .call()
      .then((data) => {
        console.log(data);
        let price = web3.utils.fromWei(data.muAVAX, "ether");
        price = parseFloat(price).toFixed(5);
        setMuAvaxSushi(price);
      });

    contract.methods
      .get_mu_avax_traderjoes_pool_info()
      .call()
      .then((data) => {
        console.log(data);
        let price = web3.utils.fromWei(data.muAVAX, "ether");
        price = parseFloat(price).toFixed(5);
        setMuAvaxTraderjoe(price);
      });

    contract.methods
      .get_mu_daie_traderjoes_pool_info()
      .call()
      .then((data) => {
        console.log(data);
        let price = web3.utils.fromWei(data.muDAI, "ether");
        price = parseFloat(price).toFixed(5);
        setMuDaiTraderjoe(price);
      });

    contract.methods
      .get_mu_mim_traderjoes_pool_info()
      .call()
      .then((data) => {
        console.log(data);
        let price = web3.utils.fromWei(data.muMIM, "ether");
        price = parseFloat(price).toFixed(5);
        setMuMimTraderjoe(price);
      });

    contract.methods
      .get_mu_usdce_traderjoes_pool_info()
      .call()
      .then((data) => {
        console.log(data);
        let price = web3.utils.fromWei(data.muUSDe, "ether");
        price = parseFloat(price).toFixed(5);
        setMuUSDCETraderjoe(price);
      });

    contract.methods
      .get_mu_usdte_traderjoes_pool_info()
      .call()
      .then((data) => {
        console.log(data);
        let price = web3.utils.fromWei(data.muUSDT, "ether");
        price = parseFloat(price).toFixed(5);
        setMuUSDTETraderjoe(price);
      });
  };

  useEffect(() => {
    if (speakDisabled) {
      speak("Speak Enabled");
    }
  }, [speakDisabled]);

  //  code added

  const [showModal, setShowModal] = useState(props.swapModal);
  const [amountConverted, setAmountConverted] = React.useState(false);
  const swapModalFn = () => {
    setShowModal(!showModal);
    setAmountConverted(false);
  };

  const [showMuVaultModal, setShowMuVaultModal] = useState(
    props.swapMuVaultModal
  );

  const setSwapMuVaultModalFn = () => {
    setShowMuVaultModal(!showMuVaultModal);
    setAmountConverted(false);
  };
  const [showMuMoneyModal, setShowMuMoneyModal] = useState(
    props.swapMuMoneyModal
  );
  const swapMuMoneyModalFn = () => {
    setShowMuMoneyModal(!showMuMoneyModal);
    setAmountConverted(false);
  };

  const [showLite, setShowLite] = useState(props.goLite);
  const setShowLiteFn = () => {
    setShowLite(!showLite);
  };

  return (
    <main
      className={
        !showLite ? "main d-flex f-column lite" : "main d-flex f-column"
      }
    >
      <Header
        // swapModal={swapModalFn}
        showMuMoneyModal={swapMuMoneyModalFn}
        goLite={setShowLiteFn}
        muUSDPrice={muUSDPrice}
        mugUSDPrice={mugUSDPrice}
      />
      <MidContent
        muUSDPrice={muUSDPrice}
        mugUSDPrice={mugUSDPrice}
        swapModal={swapModalFn}
        setSwapMuVaultModalFn={setSwapMuVaultModalFn}
      />
      <Footer />
      {showModal && (
        <SwapModal
          closeSwapModal={swapModalFn}
          setAmountConverted={setAmountConverted}
          amountConverted={amountConverted}
        />
      )}

      {showMuVaultModal && (
        <MuVaultWrap
          closeSwapMuMoneyModal={swapMuMoneyModalFn}
          setAmountConverted={setAmountConverted}
          amountConverted={amountConverted}
        />
      )}
    </main>
  );
};

export default IndexPage;
