import React, { useState, useRef, useEffect } from "react";
import MetaMaskOnboarding from "@metamask/onboarding";
import Web3 from "web3";
import contractsJSON from "../abi.json";
import usdce from "../assets/images/usdc.e-1@2x.png";
import daie from "../assets/images/DAI.e@2x.png";
import usdt from "../assets/images/usdt@2x.png";
import mim from "../assets/images/MIM@2x.png";
import busd from "../assets/images/BUSD.e@2x.png";
import mum from "../assets/images/Mu_money_Logo_5_PNG@2x.png";

// Codes Added
const ONBOARD_TEXT = "Click here to install MetaMask!";
const CONNECT_TEXT = "Connect To MetaMask";
const CONNECTED_TEXT = "Connected";

const MUAddress = "0xD036414fa2BCBb802691491E323BFf1348C5F4Ba";
const MUGAddress = "0xF7ed17f0Fb2B7C9D3DDBc9F0679b2e1098993e81";
const MUOAddress = "0x561f2209eA45023d796bF42F0402B33bc26610ce";
const MUVAddress = "0xdbA664085ae73CF4E4eb57954BDC88Be297B1f09";
const MUMAddress = "0x875E3352Baae84c38d930DD7Fd796178b2FCaa05";
const MUBAddress = "0xA472Ed02182ED5B1C71D803d0Fed098F0C7C77A9";

const USDCAddress = "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E";
const USDCeAddress = "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664";
const USDTAddress = "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7";
const USDTeAddress = "0xc7198437980c041c805A1EDcbA50c1Ce5db95118";
const DAIeAddress = "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70";
const MIMAddress = "0x130966628846BFd36ff31a822705796e8cb8C18D";
const BUSDeAddress = "0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98";

const minABI = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
];

const coins = {
  MU: {
    type: "ERC20",
    options: {
      address: MUAddress,
      symbol: "MU",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xD036414fa2BCBb802691491E323BFf1348C5F4Ba/logo.png",
    },
  },
  MUG: {
    type: "ERC20",
    options: {
      address: MUGAddress,
      symbol: "MUG",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xF7ed17f0Fb2B7C9D3DDBc9F0679b2e1098993e81/logo.png",
    },
  },
  MUO: {
    type: "ERC20",
    options: {
      address: MUOAddress,
      symbol: "MUO",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xF7ed17f0Fb2B7C9D3DDBc9F0679b2e1098993e81/logo.png",
    },
  },
  MUV: {
    type: "ERC20",
    options: {
      address: MUVAddress,
      symbol: "MUV",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xdbA664085ae73CF4E4eb57954BDC88Be297B1f09/logo.png",
    },
  },
  MUM: {
    type: "ERC20",
    options: {
      address: MUMAddress,
      symbol: "MUM",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x875E3352Baae84c38d930DD7Fd796178b2FCaa05/logo.png",
    },
  },
  MUB: {
    type: "ERC20",
    options: {
      address: MUBAddress,
      symbol: "MUM",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xA472Ed02182ED5B1C71D803d0Fed098F0C7C77A9/logo.png",
    },
  },
  USDC: {
    type: "ERC20",
    options: {
      address: USDCAddress,
      symbol: "USDC",
      decimals: 6,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E/logo.png",
    },
  },
  USDCE: {
    type: "ERC20",
    options: {
      address: USDCeAddress,
      symbol: "USDCE",
      decimals: 6,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664/logo.png",
    },
  },
  USDT: {
    type: "ERC20",
    options: {
      address: USDTAddress,
      symbol: "USDT",
      decimals: 6,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7/logo.png",
    },
  },
  USDTe: {
    type: "ERC20",
    options: {
      address: USDTeAddress,
      symbol: "USDTe",
      decimals: 6,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xc7198437980c041c805A1EDcbA50c1Ce5db95118/logo.png",
    },
  },
  DAIe: {
    type: "ERC20",
    options: {
      address: DAIeAddress,
      symbol: "DAIe",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xd586E7F844cEa2F87f50152665BCbc2C279D8d70/logo.png",
    },
  },
  MIM: {
    type: "ERC20",
    options: {
      address: MIMAddress,
      symbol: "MIM",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x130966628846BFd36ff31a822705796e8cb8C18D/logo.png",
    },
  },
  BUSDe: {
    type: "ERC20",
    options: {
      address: BUSDeAddress,
      symbol: "BUSDe",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98/logo.png",
    },
  }
};
// Codes Added

const MuStableCoinWrap = (props) => {
  // Codes Added
  // States used to check if the connection to metamask walet is on or off
  const [buttonText, setButtonText] = React.useState(ONBOARD_TEXT);
  const [isDisabled, setDisabled] = React.useState(false);
  const [wrongNetwork, setWrongNetwork] = React.useState(false);
   // States used to check if the connection to metamask walet is on or off

  //  The states to store the balances of Mu Ecosystem coins
  const [muBalance, setMUBalance] = React.useState(0);
  const [mugBalance, setMUGBalance] = React.useState(0);
  const [muoBalance, setMUOBalance] = React.useState(0);
  const [accounts, setAccounts] = React.useState([]);
  //  The states to store the balances of Mu Ecosystem coins
  const onboarding = React.useRef();


  const [muMoneyWrapVal, setMuMoneyWrapVal] = React.useState(null);

  const [approveDisabled, setApproveDisabled] = useState(false);
  const [swapDisabled, setSwapDisabled] = useState(true);

  const [amountApproved, setAmountApproved] = React.useState(false);
  const [amountIsApproving, setAmountIsApproving] = React.useState(false);
  const [amountIsConverting, setAmountIsConverting] = React.useState(false);


  const [mUMAlreadyApprovedAmount, setMUMAlreadyApprovedAmount] = useState(0);
  

  React.useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
    // pastLogsOfMU();
  }, []);

  React.useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length > 0) {
        setButtonText(CONNECTED_TEXT);
        setDisabled(true);
        onboarding.current.stopOnboarding();
        getBalance("MU");
        getBalance("MUG");
        getBalance("MUO");
      } else {
        setButtonText(CONNECT_TEXT);
        setDisabled(false);
        setMUBalance(0);
        setMUGBalance(0);
        setMUOBalance(0);
      }
    }
  }, [accounts]);

  React.useEffect(() => {
    function handleNewAccounts(newAccounts) {
      setAccounts(newAccounts);
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length === 0 && window.ethereum.selectedAddress) {
        handleNewAccounts([window.ethereum.selectedAddress]);
        checkChainId();
      }
    }
  });

  const handleChainChanged = (_chainId) => {
    // checking if chain is of avalanche network
    if (_chainId !== "0xa86a") {
      setButtonText("Wrong Network! Select Avalanche");
      setDisabled(true);
      setWrongNetwork(true);
    } else {
      setWrongNetwork(false);
      if (window.ethereum.selectedAddress) {
        setButtonText(CONNECTED_TEXT);
        setDisabled(true);
        onboarding.current.stopOnboarding();
        getApprovedAmount();
      } else {
        setButtonText(CONNECT_TEXT);
        setDisabled(false);
      }
    }
  };
  const checkChainId = async () => {
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    handleChainChanged(chainId);
  };

  React.useEffect(() => {
    function handleNewAccounts(newAccounts) {
      setAccounts(newAccounts);
      checkChainId();
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum.on("accountsChanged", handleNewAccounts);
      window.ethereum.on("chainChanged", handleChainChanged);
      return () => {
        window.ethereum.removeListener("accountsChanged", handleNewAccounts);
        window.ethereum.on("chainChanged", handleChainChanged);
      };
    }
  }, []);

//This function is used to get the balance of the differebt Coins 

  const getBalance = async (type) => {
    try {
      let address = coins[type].options.address;
      let web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(minABI, address);
      const result = await contract.methods
        .balanceOf(window.ethereum.selectedAddress)
        .call();
      const format = web3.utils.fromWei(result);
      if (type === "MU") {
        setMUBalance(format);
      } else if (type === "MUG") {
        setMUGBalance(format);
      } else if (type === "MUO") {
        setMUOBalance(format);
      }
    } catch (addError) {
      console.log(addError);
    }
  };



  // Put max value starts
  const setPutMaxFn = () => {
    setMuMoneyWrapVal(props.mumBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
  };

  // Put max value ends



  // This function is used to get the approval from Mu Money Contract for its convertion to Selected stable coin

/** Converting MUM to StableCoin  */
const redeemStablecoin = async () => {
  try {
    const mubContractAddress = `0xA472Ed02182ED5B1C71D803d0Fed098F0C7C77A9`;
    const mumContractAddress = `0x875E3352Baae84c38d930DD7Fd796178b2FCaa05`;
    const web3 = new Web3(window.ethereum);
    const weiValue = web3.utils.toWei(muMoneyWrapVal);       
    console.log ("the value added to conver is",muMoneyWrapVal );
    setAmountIsApproving(true);

    const mumContract = new web3.eth.Contract(
      contractsJSON.muMONEYContract,
      mumContractAddress
    );
    //console.log(mubContract);

    let transactionData = await mumContract.methods
      .approve(mubContractAddress, weiValue)
      .send({ from: window.ethereum.selectedAddress });
    console.log(transactionData);
    if (transactionData.status === true) {
      //alert("MU ORE Approved");
      setAmountIsApproving(false);

      setAmountApproved(true);
      setTimeout(() => {
        setAmountApproved(false);
      }, 4000);
      setSwapDisabled(false);
      setApproveDisabled(true);
      getApprovedAmount();
    } else {
      setAmountIsApproving(false);
    }
  } catch (err) {
    console.log(err);
    setAmountIsApproving(false);
  }
};
// This function is used to get the Swap the approved Mu Money to Selected stable coin by using MU bank Contract
const swapFromMUM = async () => {
  try {
    const mubContractAddress = `0xA472Ed02182ED5B1C71D803d0Fed098F0C7C77A9`;
    const web3 = new Web3(window.ethereum);
    const weiValue = web3.utils.toWei(muMoneyWrapVal);
    setAmountIsConverting(true);
    const mubContract = new web3.eth.Contract(
      contractsJSON.muBankContract,
      mubContractAddress
    );
    console.log(mubContract);
    const selectedCoinContractAddress = stableCoinSelectedAddress;
    let transactionData = await mubContract.methods
      .redeem_mu_money(selectedCoinContractAddress, weiValue)
      .send({ from: window.ethereum.selectedAddress });

    console.log(transactionData);
    if (transactionData.status === true) {
      setAmountConvertedMuMoney(true);
      setAmountIsConverting(false);
      setSwapDisabled(true);
      setApproveDisabled(false);
      getApprovedAmount();
      // getBalance("MUM");
      setMuMoneyWrapVal(null);
    } else {
      setAmountIsConverting(false);
    }
  } catch (err) {
    console.log(err);
    setAmountIsConverting(false);
  }
};
// This function is used to get the approved Value for the conversion

const getApprovedAmount = () => {
  const selectedCoinContractAddress = stableCoinSelectedAddress;
    const mubContractAddress = `0xA472Ed02182ED5B1C71D803d0Fed098F0C7C77A9`;
  const web3 = new Web3(window.ethereum);

  const mubContract = new web3.eth.Contract(
    contractsJSON.muBankContract,
    selectedCoinContractAddress
  );
  console.log("The approving contract is",mubContract);
  mubContract.methods
    .allowance(window.ethereum.selectedAddress, mubContractAddress)
    .call()
    .then((data) => {
      console.log("Already Approved Amount", data);
      setMUMAlreadyApprovedAmount(web3.utils.fromWei(data));
    });
  console.log(
    "the already approved balance value is Testings",
    mUMAlreadyApprovedAmount
  );
};
React.useEffect(() => {
  console.log(
    "the already approved balance value is Testings Next Test",
    mUMAlreadyApprovedAmount
  );
  console.log(
    "the inseted balance value is Testings Next Test Testings",
    muMoneyWrapVal
  );
  if (mUMAlreadyApprovedAmount) {
    setMuMoneyWrapVal(mUMAlreadyApprovedAmount);
    setSwapDisabled(false);
    setMuMoneyWrapVal(mUMAlreadyApprovedAmount);
    setApproveDisabled(true);
    setApproveBtnDisabled(false);
    setDipositBtnDisabled(true);
  } else {
    setSwapDisabled(true);
    setApproveDisabled(false);
    setDipositBtnDisabled(true);
  }
}, []);

/** Converting MUM to StableCoin ends here */





  // Codes Added
  let dollarUSLocale = Intl.NumberFormat("en-US");

  const [stableCoinSelected, setStableCoinSelected] = useState(0);
  const [stableCoinSelectedAddress, setStableCoinSelectedAddress] = useState();
  const setStableCoinSelectedFn = (divNum, coinConractAddress) => () => {
    setStableCoinSelected(divNum);
    setStableCoinSelectedAddress(coinConractAddress)
    console.log("The selected coin address is ", stableCoinSelectedAddress)
    setApproveBtnDisabled(false);
  };

  const [approveBtnDisabled, setApproveBtnDisabled] = useState(true);
  const [dipositBtnDisabled, setDipositBtnDisabled] = useState(true);
  const [amountConvertedMuMoney, setAmountConvertedMuMoney] = useState(false);

  const setApproveBtnDisabledFn = () => {
    setApproveBtnDisabled(true);
    setDipositBtnDisabled(false);
  };

  const setDipositBtnDisabledFn = () => {
    setDipositBtnDisabled(true);
  };

  return (
    <div
      className={
        amountConvertedMuMoney
          ? "modal-content succesFailResult"
          : "modal-content"
      }
    >
      {amountConvertedMuMoney ? (
        <div className="swappingWraper">
          <div className="modalContentFirstCondition">
            <div class="modalHedading">
              <span
                id="swapModalclose"
                className="close-button"
                onClick={props.closeSwapMuMoneyModal}
              >
                <figure className="closeModal">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.121"
                    height="16.121"
                    viewBox="0 0 16.121 16.121"
                  >
                    <g
                      id="Group_147"
                      data-name="Group 147"
                      transform="translate(-676.999 -363.999)"
                    >
                      <line
                        id="Line_6"
                        data-name="Line 6"
                        x2="14"
                        y2="14"
                        transform="translate(678.06 365.06)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-width="1.5"
                      />
                      <line
                        id="Line_7"
                        data-name="Line 7"
                        x1="14"
                        y2="14"
                        transform="translate(678.06 365.06)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-width="1.5"
                      />
                    </g>
                  </svg>
                </figure>
              </span>
            </div>
            <div class="modalSuccess">
              <h1>Congratulations !!</h1>
              <p>
                Your <span class="hilighted">Mu Money</span> is successfully converted.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="swappingWraper">
          {accounts.length ? (
            <div className="modalContentFirstCondition">
              {!wrongNetwork ? (
                <div className="modalContentSecondCondition muMoneyConvertion">
                  <div className="modalHedading">
                    <span className="conversionRate muMoneyModalHeading">
                      Redeem Mu money into Stable coin
                    </span>
                    <span
                      id="swapModalclose"
                      className="close-button"
                      onClick={props.closeSwapMuMoneyModal}
                    >
                      <figure className="closeModal">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.121"
                          height="16.121"
                          viewBox="0 0 16.121 16.121"
                        >
                          <g
                            id="Group_147"
                            data-name="Group 147"
                            transform="translate(-676.999 -363.999)"
                          >
                            <line
                              id="Line_6"
                              data-name="Line 6"
                              x2="14"
                              y2="14"
                              transform="translate(678.06 365.06)"
                              fill="none"
                              stroke="#fff"
                              stroke-linecap="round"
                              stroke-width="1.5"
                            />
                            <line
                              id="Line_7"
                              data-name="Line 7"
                              x1="14"
                              y2="14"
                              transform="translate(678.06 365.06)"
                              fill="none"
                              stroke="#fff"
                              stroke-linecap="round"
                              stroke-width="1.5"
                            />
                          </g>
                        </svg>
                      </figure>
                    </span>
                  </div>
                  
                  <div className="modalBody muMoneyRedeem">
                    <div className="firstConvertRow">
                      <figure className="currencyIcon">
                        <img src={mum} alt="" />
                        <span>Mu money</span>
                      </figure>
                      <span className="inputFieldSection">
                        <input
                          type="number"
                          className="inputAmt readOnly"
                          value={muMoneyWrapVal}
                          placeholder="Amount"
                          min="1"
                          onChange={(e) =>
                            setMuMoneyWrapVal(e.currentTarget.value)
                          }
                        />
                      </span>
                      <span className="btnMax">
                        <button className="maxButton valueBtn redeemBtns"
                        onClick={() => setPutMaxFn()}>
                        
                          Max <span className="redeemMaxAmt">{dollarUSLocale.format(
                                    props.mumBalance
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}</span>
                        </button>
                      </span>
                    </div>

                    <div className="alterBtnRow">
                      <figure
                        className="swapIcons"
                        onClick={() => props.setMuMoneyWrapFn()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="46"
                          height="46"
                          viewBox="0 0 46 46"
                        >
                          <g
                            id="Group_155"
                            data-name="Group 155"
                            transform="translate(-937 -548)"
                          >
                            <circle
                              id="Ellipse_25"
                              data-name="Ellipse 25"
                              cx="23"
                              cy="23"
                              r="23"
                              transform="translate(937 548)"
                              fill="#151f3e"
                            />
                            <g id="sort" transform="translate(950.001 561)">
                              <path
                                id="Path_3647"
                                data-name="Path 3647"
                                d="M227.673,20a.781.781,0,0,1-.781-.781V6.875h-3.359a1.527,1.527,0,0,1-1.078-2.6L226.013.69a2.341,2.341,0,0,1,3.32,0l3.558,3.579a1.527,1.527,0,0,1-1.078,2.6h-.859a.781.781,0,0,1,0-1.563h.77l-3.5-3.52a.778.778,0,0,0-1.1,0l-3.5,3.52h3.27a1.564,1.564,0,0,1,1.563,1.563V19.219A.781.781,0,0,1,227.673,20Zm0,0"
                                transform="translate(-213.338)"
                                fill="#fff"
                              />
                              <path
                                id="Path_3648"
                                data-name="Path 3648"
                                d="M5.663,20A2.325,2.325,0,0,1,4,19.309L.444,15.73a1.528,1.528,0,0,1,1.078-2.6H4.882V.781a.781.781,0,1,1,1.563,0V13.125a1.564,1.564,0,0,1-1.563,1.563H1.611l3.5,3.52a.778.778,0,0,0,1.1,0l3.5-3.52h-.77a.781.781,0,0,1,0-1.563H9.8a1.527,1.527,0,0,1,1.078,2.6L7.323,19.309a2.325,2.325,0,0,1-1.66.69Zm0,0"
                                transform="translate(0 0)"
                                fill="#fff"
                              />
                            </g>
                          </g>
                        </svg>
                      </figure>
                    </div>

                    <div className="modalSubHedading muMoneyModal redeemWrap">
                      <span className="wrapUnwrap">Select stable coin</span>
                    </div>

                    <div className="stableCoinRow">
                      <div
                      id={(props.usdcBankBalance == 0 || props.converstionStatusUSDC == false) ? "notAccepted": "accepted"}
                        className={
                          stableCoinSelected == 1
                            ? "indStableCoinsWraper activated"
                            : "indStableCoinsWraper"
                        }
                        onClick={setStableCoinSelectedFn(1, USDCAddress)}
                      >
                        <div className="stablecoinInfo">
                          <img src={usdce} alt="" />
                          <p>USDC</p>
                        </div>
                        <h4>{dollarUSLocale.format(
                                    props.usdcBankBalance
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}</h4>
                      </div>

                      <div
                      id={(props.usdceBankBalance == 0 || props.converstionStatusUSDCe == false) ? "notAccepted": "accepted"}
                        className={
                          stableCoinSelected == 2
                            ? "indStableCoinsWraper activated"
                            : "indStableCoinsWraper"
                        }
                        onClick={setStableCoinSelectedFn(2, USDCeAddress)}
                      >
                        <div className="stablecoinInfo">
                          <img src={usdce} alt="" />
                          <p>USDC.e</p>
                        </div>
                        <h4>{dollarUSLocale.format(
                                    props.usdceBankBalance
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}</h4>
                      </div>

                      <div
                      id={(props.daieBankBalance == 0 || props.converstionStatusDAIe == false) ? "notAccepted": "accepted"}
                        className={
                          stableCoinSelected == 3
                            ? "indStableCoinsWraper activated"
                            : "indStableCoinsWraper"
                        }
                        onClick={setStableCoinSelectedFn(3, DAIeAddress)}
                      >
                        <div className="stablecoinInfo">
                          <img src={daie} alt="" />
                          <p>DAI.e</p>
                        </div>
                        <h4>{dollarUSLocale.format(
                                    props.daieBankBalance
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}</h4>
                      </div>

                      <div
                      id={(props.usdtBankBalance == 0 || props.converstionStatusUSDT == false) ? "notAccepted": "accepted"}
                        className={
                          stableCoinSelected == 4
                            ? "indStableCoinsWraper activated"
                            : "indStableCoinsWraper"
                        }
                        onClick={setStableCoinSelectedFn(4, USDTAddress)}
                      >
                        <div className="stablecoinInfo">
                          <img src={usdt} alt="" />
                          <p>USDT</p>
                        </div>
                        <h4>{dollarUSLocale.format(
                                    props.usdtBankBalance
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}</h4>
                      </div>

                      <div
                      id={(props.usdteBankBalance == 0 || props.converstionStatusUSDTe == false) ? "notAccepted": "accepted"}
                        className={
                          stableCoinSelected == 5
                            ? "indStableCoinsWraper activated"
                            : "indStableCoinsWraper"
                        }
                        onClick={setStableCoinSelectedFn(5, USDTeAddress)}
                      >
                        <div className="stablecoinInfo">
                          <img src={usdt} alt="" />
                          <p>USDT.e</p>
                        </div>
                        <h4>{dollarUSLocale.format(
                                    props.usdteBankBalance
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}</h4>
                      </div>

                      <div
                      id={(props.mimBankBalance == 0 || props.converstionStatusMIM == false) ? "notAccepted": "accepted"}
                        className={
                          stableCoinSelected == 6
                            ? "indStableCoinsWraper activated"
                            : "indStableCoinsWraper"
                        }
                        onClick={setStableCoinSelectedFn(6, MIMAddress)}
                      >
                        <div className="stablecoinInfo">
                          <img src={mim} alt="" />
                          <p>MIM</p>
                        </div>
                        <h4>{dollarUSLocale.format(
                                    props.mimBankBalance
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}</h4>
                      </div>

                      <div
                      id={(props.busdeBankBalance == 0 || props.converstionStatusBUSDe == false) ? "notAccepted": "accepted"}
                        className={
                          stableCoinSelected == 7
                            ? "indStableCoinsWraper activated"
                            : "indStableCoinsWraper"
                        }
                        onClick={setStableCoinSelectedFn(7, BUSDeAddress)}
                      >
                        <div className="stablecoinInfo">
                          <img src={busd} alt="" />
                          <p>BUSD.e</p>
                        </div>
                        <h4>{dollarUSLocale.format(
                                    props.busdeBankBalance
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}</h4>
                      </div>
                    </div>

                    <div className="firstConvertRow">
                      {stableCoinSelected == 1 && (
                        <figure className="currencyIcon">
                          <img src={usdce} alt="" />
                          <span>USDC</span>
                        </figure>
                      )}

                      {stableCoinSelected == 2 && (
                        <figure className="currencyIcon">
                          <img src={usdce} alt="" />
                          <span>USDC.e</span>
                        </figure>
                      )}

                      {stableCoinSelected == 3 && (
                        <figure className="currencyIcon">
                          <img src={daie} alt="" />
                          <span>DAI.e</span>
                        </figure>
                      )}

                      {stableCoinSelected == 4 && (
                        <figure className="currencyIcon">
                          <img src={usdt} alt="" />
                          <span>USDT</span>
                        </figure>
                      )}

                      {stableCoinSelected == 5 && (
                        <figure className="currencyIcon">
                          <img src={usdt} alt="" />
                          <span>USDT.e</span>
                        </figure>
                      )}

                      {stableCoinSelected == 6 && (
                        <figure className="currencyIcon">
                          <img src={mim} alt="" />
                          <span>MIM</span>
                        </figure>
                      )}

                      {stableCoinSelected == 7 && (
                        <figure className="currencyIcon">
                          <img src={busd} alt="" />
                          <span>BUSD.e</span>
                        </figure>
                      )}
                      <span className="inputFieldSection">
                        <input
                          type="number"
                          className="inputAmt"
                          placeholder="Select the stable coin"
                          min="1"
                          value={muMoneyWrapVal}
                          readOnly
                        />
                      </span>
                      {/* <span className="btnMax">
                  <button onClick={() =>setPutMaxFn()} className="maxButton valueBtn">Max</button>
                </span> */}
                    </div>
                      <p className="approvedSuccess">
                        {amountApproved ? (
                          <span>
                            Approved. You may now complete your deposit.
                          </span>
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </p>
                      {amountIsApproving ? (
                        <p className="approvingSuccess">
                          <span>
                            Please wait... Your conversion approval is in
                            pogress
                          </span>
                        </p>
                      ) : (
                        <div className="buttonDiv">
                          {amountIsConverting ? (
                            <p className="approvingSuccess">
                              <span>
                                Please wait... Mu Money deposit is in pogress
                              </span>
                            </p>
                          ) : (
                            <div className="buttonWrapers">
                              {approveDisabled ? (
                                <button
                                  onClick={swapFromMUM}
                                  disabled={swapDisabled}
                                  className="swapBtn btn-gradient approved"
                                >
                                  Deposit
                                </button>
                              ) : (
                                <button
                                  onClick={redeemStablecoin}
                                  disabled={approveDisabled}
                                  className="swapBtn btn-gradient"
                                >
                                  Approve
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      )}

                    {/* <div className="buttonDiv">
                      <div className="buttonWrapers">
                        <button
                          onClick={setApproveBtnDisabledFn}
                          disabled={approveBtnDisabled}
                          className={
                            approveBtnDisabled
                              ? "maxButton btn-disabled halfBtn"
                              : "maxButton btn-gradient halfBtn"
                          }
                        >
                          Approve
                        </button>
                        <button
                          onClick={setDipositBtnDisabledFn}
                          disabled={dipositBtnDisabled}
                          className={
                            dipositBtnDisabled
                              ? "swapBtn btn-gradient disabled btnHalf"
                              : "swapBtn btn-gradient approved btnHalf"
                          }
                        >
                          Deposit
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
              ) : (
                <div className="modalHedading">
                  <span className="conversionRate">
                    Wrong Network! Select Avalanche
                  </span>
                  <span
                    id="swapModalclose"
                    className="close-button"
                    onClick={props.closeSwapMuMoneyModal}
                  >
                    <figure className="closeModal">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.121"
                        height="16.121"
                        viewBox="0 0 16.121 16.121"
                      >
                        <g
                          id="Group_147"
                          data-name="Group 147"
                          transform="translate(-676.999 -363.999)"
                        >
                          <line
                            id="Line_6"
                            data-name="Line 6"
                            x2="14"
                            y2="14"
                            transform="translate(678.06 365.06)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-width="1.5"
                          />
                          <line
                            id="Line_7"
                            data-name="Line 7"
                            x1="14"
                            y2="14"
                            transform="translate(678.06 365.06)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-width="1.5"
                          />
                        </g>
                      </svg>
                    </figure>
                  </span>
                </div>
              )}
            </div>
          ) : (
            <div className="modalHedading logoutTexts">
              <span className="conversionRate">
                Kindly Connect To MetaMask to access the Swaping feature.{" "}
              </span>
              <span
                id="swapModalclose"
                className="close-button"
                onClick={props.closeSwapMuMoneyModal}
              >
                <figure className="closeModal">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.121"
                    height="16.121"
                    viewBox="0 0 16.121 16.121"
                  >
                    <g
                      id="Group_147"
                      data-name="Group 147"
                      transform="translate(-676.999 -363.999)"
                    >
                      <line
                        id="Line_6"
                        data-name="Line 6"
                        x2="14"
                        y2="14"
                        transform="translate(678.06 365.06)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-width="1.5"
                      />
                      <line
                        id="Line_7"
                        data-name="Line 7"
                        x1="14"
                        y2="14"
                        transform="translate(678.06 365.06)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-width="1.5"
                      />
                    </g>
                  </svg>
                </figure>
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MuStableCoinWrap;
